import {
  LoginWrapper,
  Container,
  BodyContent,
  ImageHolder,
  ImgLogo,
  FormHeadingBox,
  FormHeading,
  FormSubHeading,
  StyledForm,
  FormInput,
  FormItem,
  SignInBtn,
  SignUpText,
} from "containers/Login/style";
import { IconBox, Icon, BackIcon, BackLink, ResetLink } from "./style";
import iconLogo from "assets/images/website-logo.svg";
import React, { useEffect, useState } from "react";
import { passwordValidation } from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import { tenantService } from "services/tenantService";
import { tostor } from "components/helpers";
import jwt_decode from "jwt-decode";
import moment from "moment";

function ConfirmPassword(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = StyledForm.useForm();
  const initialTimer = 29;
  const [emailTimer, setEmailTimer] = useState(initialTimer);
  const [screen, setScreen] = useState(0);
  const [decodedData, setDecodedData] = useState({});
  const [linkExpire, setLinkExpire] = useState(false);
  const [resetWithSms, setResetWithSMS] = useState(false);
  const [tokenData, setTokenData] = useState({});
  const localResetData = JSON.parse(
    localStorage.getItem("localResetData") || null
  );
  const [deliveryType, setDeliveryType] = useState(localResetData);

  const urlParams = location?.search;
  const token = new URLSearchParams(urlParams)?.get("token") || "";
  const confirmationCode =
    new URLSearchParams(urlParams)?.get("confirmation_code") || "";

  const fetchResetLink = () => {
    let data = {
      Tenant: tokenData?.TenantName || decodedData?.Tenant?.TenantName,
      Email: tokenData?.email || decodedData?.Email,
      apiUrl: tokenData?.apiUrl || decodedData?.Tenant?.UserApiRootDomain,
    };
    tenantService
      .forgotPassword(data, data?.apiUrl)
      .then((res) => {
        setEmailTimer(initialTimer);
        let localResetData = {
          CodeDeliveryDetails: res?.data?.CodeDeliveryDetails,
          ExpiryTime: res?.data?.ExpiryTime,
        };
        setDeliveryType(localResetData);
        localStorage.removeItem("localResetData");
        localStorage.setItem("localResetData", JSON.stringify(localResetData));
      })
      .catch((error) => {
        tostor.error(error.message);
      });
  };

  const handleSubmit = (values, form) => {
    if (screen === 0) {
      let data = {
        ConfirmationCode: form.getFieldValue("otp"),
        Password: form.getFieldValue("password"),
        Tenant: decodedData?.Tenant?.TenantName,
        Email: decodedData?.Email,
      };
      tenantService
        .resetPassword(data, decodedData?.Tenant?.UserApiRootDomain)
        .then((res) => {
          localStorage.clear();
          tostor.success(res.message);
          setScreen(screen + 1);
        })
        .catch((error) => {
          tostor.error(error.message);
        });
    } else {
      redirectToLogin();
    }
  };
  const getText = (screen) => {
    switch (screen) {
      case 0:
        return {
          icon: "icon-featured-key",
          heading: "Set new password",
          subHeading:
            "Your new password must be different to previously used passwords.",
          ctaText: "Reset Password",
        };
      case 1:
        return {
          icon: "icon-success-circle",
          heading: "Password reset",
          subHeading:
            "Your password has been successfully reset. Click below to log in magically.",
          ctaText: "Continue",
        };
      default:
        return {
          icon: "icon-featured-key",
          heading: "Set new password",
          subHeading:
            "Your new password must be different to previously used passwords.",
          ctaText: "Reset Password",
        };
    }
  };
  const data = getText(screen);

  const redirectToLogin = () => {
    navigate("/login");
  };
  useEffect(() => {
    if (!token && deliveryType !== null) {
      setResetWithSMS(true);
      let resetData = JSON.parse(localStorage.getItem("resetData"));
      let decoded = JSON.parse(localStorage.getItem("selectedAccount"));
      let tokenLocalData = {
        email:
          resetData?.email !== decoded?.emailId
            ? resetData?.email
            : decoded?.emailId,
        TenantName: decoded?.TenantName,
        apiUrl: decoded?.UserApiRootDomain,
      };
      let DecodedData = {
        Tenant: {
          TenantName: decoded?.TenantName,
          UserApiRootDomain: decoded?.UserApiRootDomain,
        },
        Email:
          resetData?.email !== decoded?.emailId
            ? resetData?.email
            : decoded?.emailId,
      };
      let now = moment();
      let endTime = moment(Number(deliveryType?.ExpiryTime));
      let duration = endTime.diff(now, "seconds");
      if (duration > 0) {
        setDecodedData(DecodedData);
        setTokenData(tokenLocalData);
      } else {
        setLinkExpire(true);
        tostor.error("Link expired");
        setTimeout(() => {
          redirectToLogin();
        }, 1000);
      }
    } else if (token) {
      let decoded = jwt_decode(token);
      let now = moment();
      let endTime = moment(decoded?.ExpiryTime);
      let duration = endTime.diff(now, "seconds");
      if (duration > 0) {
        // console.log("now", now.format("DD/MM/YYYY HH:mm:ss"));
        // console.log("endTime", endTime.format("DD/MM/YYYY HH:mm:ss"));
        setDecodedData(decoded);
      } else {
        setLinkExpire(true);
        tostor.error("Link expired");
        setTimeout(() => {
          redirectToLogin();
        }, 1000);
      }
    } else if (!token || deliveryType === null) {
      setResetWithSMS(false);
      redirectToLogin();
    }
  }, [token, deliveryType]);

  const emailTimerHandler = (count) => {
    let totalTime = count;
    let timer = setInterval(function () {
      totalTime = totalTime - 1;
      setEmailTimer(totalTime);
      if (totalTime < 0) {
        clearInterval(timer);
      }
    }, 1000);
    return totalTime;
  };
  const resetEmailTimer = () => {
    fetchResetLink();
  };
  useEffect(() => {
    if (screen === 0 && emailTimer >= initialTimer) {
      emailTimerHandler(initialTimer);
    }
  }, [screen, emailTimer]);
  useEffect(() => {
    if (confirmationCode && form.getFieldValue("otp") === undefined) {
      form.setFieldValue("otp", confirmationCode);
    }
  }, [confirmationCode]);
  return (
    <LoginWrapper>
      <Container>
        <BodyContent>
          {!linkExpire && (resetWithSms || token) && (
            <>
              <ImageHolder>
                <ImgLogo src={iconLogo} />
              </ImageHolder>
              <FormHeadingBox>
                {data.icon && (
                  <IconBox>
                    <Icon icon={data.icon} />
                  </IconBox>
                )}
                {data?.heading && (
                  <FormHeading weight="a900" variant="displaySM">
                    {data?.heading}
                  </FormHeading>
                )}
                {data?.subHeading && (
                  <FormSubHeading variant="textMD" weight="a400">
                    {data?.subHeading}
                  </FormSubHeading>
                )}
                {screen === 0 && (
                  <SignUpText
                    variant="textSM"
                    weight="l400"
                    className="text-center"
                    mb="0px"
                    mt="32px"
                  >
                    {emailTimer >= 0
                      ? `Confirmation Code sent to ${
                          deliveryType?.CodeDeliveryDetails?.DeliveryMedium?.toLowerCase() ===
                          "sms"
                            ? "your mobile"
                            : "your email"
                        }, Resend in`
                      : "Didn't receive the Confirmation Code?"}{" "}
                    <ResetLink
                      variant="span"
                      className="text-decoration form-footer-subtext"
                      weight="l500"
                      clickable={emailTimer < 0}
                      onClick={
                        emailTimer < 0
                          ? () => {
                              resetEmailTimer();
                            }
                          : () => {}
                      }
                    >
                      {emailTimer >= 0
                        ? `00:${emailTimer > 9 ? emailTimer : `0${emailTimer}`}`
                        : "Click to resend"}
                    </ResetLink>
                  </SignUpText>
                )}
              </FormHeadingBox>

              {screen === 0 && (
                <>
                  <StyledForm
                    layout="vertical"
                    labelCol={{
                      span: 10,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    name="control-form-password"
                    onFinish={(e) => handleSubmit(e, form)}
                    requiredMark={false}
                    size={"large"}
                    form={form}
                  >
                    <StyledForm.Item
                      name="otp"
                      label="Confirmation code"
                      rules={[
                        {
                          required: true,
                          message: "Confirmation code is Required",
                        },
                      ]}
                    >
                      <FormInput placeholder="Enter your Confirmation Code" />
                    </StyledForm.Item>
                    <StyledForm.Item
                      name="password"
                      label="New Password"
                      rules={[
                        {
                          required: true,
                          message: "Password is required",
                        },
                        {
                          validator: (_, value) => {
                            if (
                              !value ||
                              !passwordValidation(decodedData?.Email, value)
                                .valid
                            ) {
                              return Promise.resolve();
                            } else if (
                              passwordValidation(decodedData?.Email, value)
                                .valid
                            ) {
                              return Promise.reject(
                                passwordValidation(decodedData?.Email, value)
                                  .error
                              );
                            } else {
                              return Promise.reject(
                                "Email is not a valid email!"
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <FormInput.Password placeholder="Password" />
                    </StyledForm.Item>
                    <StyledForm.Item
                      name="confirm-password"
                      label="Confirm Password"
                      rules={[
                        {
                          required: true,
                          message: "Password is required",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              (!value || getFieldValue("password") === value) &&
                              !passwordValidation(decodedData?.Email, value)
                                .valid
                            ) {
                              return Promise.resolve();
                            } else if (
                              passwordValidation(decodedData?.Email, value)
                                .valid
                            ) {
                              return Promise.reject(
                                passwordValidation(decodedData?.Email, value)
                                  .error
                              );
                            } else if (getFieldValue("password") !== value) {
                              return Promise.reject("Password do not match");
                            } else {
                              return Promise.reject(
                                "Please enter confirm password"
                              );
                            }
                          },
                        }),
                      ]}
                    >
                      <FormInput.Password placeholder="Password" />
                    </StyledForm.Item>
                    {data.ctaText && (
                      <FormItem mb="32px">
                        <SignInBtn
                          buttonType="submit"
                          variant="primary"
                          btnLabel={data.ctaText}
                        />
                      </FormItem>
                    )}
                  </StyledForm>
                </>
              )}
              {screen === 1 && (
                <>
                  <FormItem width="100%">
                    <SignInBtn
                      buttonType="submit"
                      variant="primary"
                      btnLabel={data.ctaText}
                      className="ant-btn-lg"
                      onClick={(e) => handleSubmit(e, null)}
                    />
                  </FormItem>
                </>
              )}

              <BackLink
                variant="textSM"
                weight="l500"
                className="text-decoration form-footer-subtext"
                onClick={() => navigate("/login")}
              >
                <BackIcon icon="icon-arrow-left" />
                Back to log in
              </BackLink>
            </>
          )}
        </BodyContent>
      </Container>
    </LoginWrapper>
  );
}

export default ConfirmPassword;
