import React, { useState, useEffect } from "react";
import "./index.scss";
import { Form, Input, Select, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import StepsBar from "components/atoms/StepsBar";
import Header from "components/Header";
import CustomButton from "components/atoms/CustomButton";
import { ReactComponent as IconStepFinish } from "assets/images/step-finish.svg";
import { ReactComponent as IconStepProgress } from "assets/images/step-progress.svg";
import { tenantService } from "services/tenantService";
import { tostor } from "components/helpers";
import { passwordValidation } from "utils";

const AccountSetup = () => {
  const [form] = Form.useForm();
  const [user, setUser] = useState({});
  let history = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    } else {
      history("/");
    }
  }, []);

  const onFinish = (values) => {
    console.log(values);

    tenantService
      .createTenant(
        {
          TenantName: values.TenantName,
          CustomerName: user.Name,
          Email: user.Email,
          CustomerAddress: "Test",
          TenantOwnerName: user.Name,
          Password: values.Password,
        },
        user.token
      )
      .then((res) => {
        console.log(res);
        tostor.success("Account Setup is completed");
        window.location.href = res.data.AdminUrl;
      });
  };

  const steps = [
    {
      title: "Your details",
      description: "Name and email",
      icon: <IconStepFinish />,
      status: "finish",
    },
    {
      title: "Verification",
      description: "Verify your email",
      icon: <IconStepFinish />,
      status: "finish",
    },
    {
      title: "Set up your account",
      description: "Company code and password",
      icon: <IconStepProgress />,
      status: "process",
    },
  ];
  return (
    <div>
      <Header />
      <div className="col-lg-12 d-flex justify-content-center align-items-center flex-column account-setup-body">
        <h3 className="account-setup-heading">Set up your account</h3>
        <p className="account-setup-sub-heading">
          Create a company code and password.
        </p>
        <div className="form w-25">
          <Form
            className="form-body"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 24,
            }}
            layout="vertical"
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            requiredMark={false}
            size={"large"}
          >
            <Form.Item
              name="TenantName"
              label="Company Code*"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="company-code" />
            </Form.Item>
            <Form.Item
              name="Password"
              label="Password*"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
                {
                  validator: (_, value) => {
                    if (
                      !value ||
                      !passwordValidation(user.Email, value).valid
                    ) {
                      return Promise.resolve();
                    } else if (passwordValidation(user.Email, value).valid) {
                      return Promise.reject(
                        passwordValidation(user.Email, value).error
                      );
                    } else {
                      return Promise.reject("Email is not a valid email!");
                    }
                  },
                },
              ]}
            >
              <Input placeholder="Create a password" />
            </Form.Item>
            <Form.Item name="terms" valuePropName="checked">
              <Checkbox>I agree to terms and conditions.</Checkbox>
            </Form.Item>
            <Form.Item className="submit-btn">
              <CustomButton
                buttonType="submit"
                variant="primary"
                btnLabel="Create account"
              ></CustomButton>
            </Form.Item>
          </Form>
        </div>
        <div className="d-flex steps-outer">
          <StepsBar steps={steps} />
        </div>
      </div>
    </div>
  );
};

export default AccountSetup;
