import {
  LoginWrapper,
  Container,
  BodyContent,
  ImageHolder,
  ImgLogo,
  FormHeadingBox,
  FormHeading,
  FormSubHeading,
  StyledForm,
  FormInput,
  FormItem,
  SignInBtn,
  SignUpText,
} from "containers/Login/style";
import { IconBox, Icon, BackIcon, BackLink, EmailId, ResetLink } from "./style";
import iconLogo from "assets/images/website-logo.svg";
import React, { useEffect, useState } from "react";
import { isValidEmail, isValidWorkEmail } from "utils";
import { useNavigate } from "react-router-dom";
import { tenantService } from "services/tenantService";
import { tostor } from "components/helpers";

function ResetPassword(props) {
  const navigate = useNavigate();
  const screen = 0;
  const initialTimer = 29;
  const [emailTimer, setEmailTimer] = useState(initialTimer);
  const [tokenData, setTokenData] = useState({});
  const [form] = StyledForm.useForm();

  const fetchResetLink = (state) => {
    let data = {
      Tenant: tokenData?.TenantName,
      Email: form.getFieldValue("Email") || tokenData?.email,
    };
    tenantService
      .forgotPassword(data, tokenData?.apiUrl)
      .then((res) => {
        let resetData = { email: form.getFieldValue("Email") };
        localStorage.removeItem("resetData");
        localStorage.setItem("resetData", JSON.stringify(resetData));
        let localResetData = {
          CodeDeliveryDetails: res?.data?.CodeDeliveryDetails,
          ExpiryTime: res?.data?.ExpiryTime,
        };
        localStorage.removeItem("localResetData");
        localStorage.setItem("localResetData", JSON.stringify(localResetData));
        navigate("/confirm-password");
      })
      .catch((error) => {
        tostor.error(error.message);
      });
  };
  const handleSubmit = () => {
    if (screen === 0) {
      fetchResetLink(true);
    }
  };
  const getText = (screen) => {
    switch (screen) {
      case 0:
        return {
          icon: "icon-featured-key",
          heading: "Forgot password?",
          subHeading: "No worries, we'll send you reset instructions.",
          ctaText: "Reset Password",
        };
      case 1:
        return {
          icon: "icon-email-circle",
          heading: "Check your email",
          subHeading: (
            <>
              We sent a password reset link to{" "}
              {form.getFieldValue("Email") && (
                <EmailId variant="span" weight="l500">
                  {form.getFieldValue("Email")}
                </EmailId>
              )}
            </>
          ),
          ctaText: "Open email app",
        };
      case 2:
        return {
          icon: "icon-featured-key",
          heading: "Set new password",
          subHeading:
            "Your new password must be different to previously used passwords.",
          ctaText: "Reset Password",
        };
      case 3:
        return {
          icon: "icon-success-circle",
          heading: "Password reset",
          subHeading:
            "Your password has been successfully reset. Click below to log in magically.",
          ctaText: "Continue",
        };
      default:
        return {
          icon: "icon-featured-key",
          heading: "Forgot password?",
          subHeading: "No worries, we'll send you reset instructions.",
          ctaText: "Reset Password",
        };
    }
  };
  const data = getText(screen);
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("selectedAccount")) === null) {
      redirectToLogin();
    } else if (JSON.parse(localStorage.getItem("selectedAccount")) !== null) {
      let resetData = JSON.parse(localStorage.getItem("resetData"));
      let decoded = JSON.parse(localStorage.getItem("selectedAccount"));
      form.setFieldValue(
        "Email",
        resetData?.email !== decoded?.emailId
          ? resetData?.email
          : decoded?.emailId || ""
      );
      let tokenLocalData = {
        email: decoded?.emailId,
        TenantName: decoded?.TenantName,
        apiUrl: decoded?.UserApiRootDomain,
      };
      setTokenData(tokenLocalData);
    }
  }, []);

  const redirectToLogin = () => {
    navigate("/login");
  };

  const emailTimerHandler = (count) => {
    let totalTime = count;
    let timer = setInterval(function () {
      totalTime = totalTime - 1;
      setEmailTimer(totalTime);
      if (totalTime < 0) {
        clearInterval(timer);
      }
    }, 1000);
    return totalTime;
  };
  const resetEmailTimer = (state) => {
    fetchResetLink(state);
    setEmailTimer(initialTimer);
  };
  useEffect(() => {
    if (screen === 1 && emailTimer >= initialTimer) {
      emailTimerHandler(initialTimer);
    }
  }, [screen, emailTimer]);
  return (
    <LoginWrapper>
      <Container>
        <BodyContent>
          <ImageHolder>
            <ImgLogo src={iconLogo} />
          </ImageHolder>
          <FormHeadingBox>
            {data.icon && (
              <IconBox>
                <Icon icon={data.icon} />
              </IconBox>
            )}
            {data?.heading && (
              <FormHeading weight="a900" variant="displaySM">
                {data?.heading}
              </FormHeading>
            )}
            {data?.subHeading && (
              <FormSubHeading variant="textMD" weight="a400">
                {data?.subHeading}
              </FormSubHeading>
            )}
          </FormHeadingBox>
          {screen === 0 && (
            <StyledForm
              layout="vertical"
              labelCol={{
                span: 10,
              }}
              wrapperCol={{
                span: 24,
              }}
              name="control-hooks"
              onFinish={(e) => handleSubmit(e, form)}
              requiredMark={false}
              size={"large"}
              form={form}
            >
              <StyledForm.Item
                name="Email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        !value ||
                        (isValidEmail(value) && isValidWorkEmail(value))
                      ) {
                        return Promise.resolve();
                      } else if (!isValidWorkEmail(value)) {
                        return Promise.reject("Please enter work email");
                      } else {
                        return Promise.reject("Email is not a valid email!");
                      }
                    },
                  },
                ]}
              >
                <FormInput placeholder="Enter your work email" />
              </StyledForm.Item>
              {data.ctaText && (
                <FormItem mb="32px">
                  <SignInBtn
                    buttonType="submit"
                    variant="primary"
                    btnLabel={data.ctaText}
                  />
                </FormItem>
              )}
            </StyledForm>
          )}
          {screen === 1 && (
            <>
              {/* <FormItem width="100%">
                    <SignInBtn
                      buttonType="submit"
                      variant="primary"
                      btnLabel={data.ctaText}
                      className="ant-btn-lg"
                      onClick={(e) => handleSubmit(e, null)}
                    />
                  </FormItem> */}
              <SignUpText
                variant="textSM"
                weight="l400"
                className="text-center"
                mb="32px"
              >
                {emailTimer >= 0
                  ? "Resend Email in"
                  : "Didn't receive the email?"}{" "}
                <ResetLink
                  variant="span"
                  className="text-decoration form-footer-subtext"
                  weight="l500"
                  clickable={emailTimer < 0}
                  onClick={
                    emailTimer < 0
                      ? () => {
                          resetEmailTimer(false);
                        }
                      : () => {}
                  }
                >
                  {emailTimer >= 0
                    ? `00:${emailTimer > 9 ? emailTimer : `0${emailTimer}`}`
                    : "Click to resend"}
                </ResetLink>
              </SignUpText>
            </>
          )}

          <BackLink
            variant="textSM"
            weight="l500"
            className="text-decoration form-footer-subtext"
            onClick={() => navigate("/tenant/login")}
          >
            <BackIcon icon="icon-arrow-left" />
            Back to log in
          </BackLink>
        </BodyContent>
      </Container>
    </LoginWrapper>
  );
}

export default ResetPassword;
