import React from "react";

import { Span } from "./style";

const renderSpan = (props) => {
  let { children, variant, ...rest } = props;

  const spanProps = { ...rest };

  return (
    <>{["span"].includes(variant) && <Span {...spanProps}>{children}</Span>}</>
  );
};

export default renderSpan;
