import React from "react";
import "./index.scss";
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  system,
} from "styled-system";
import styled from "styled-components";

export default function SvgSprite({
  icon,
  className = "svg-style",
  useProps = {},
  width = "14px",
  height = "14px",
  ...props
}) {
  return (
    <svg
      className={`icon ${icon} ${className}`}
      {...props}
      style={{ width: width, height: height }}
    >
      <use
        xlinkHref={`${process.env.PUBLIC_URL}/images/icons.svg?#${icon}`}
        {...useProps}
      />
    </svg>
  );
}
const fill = system({
  fill: {
    property: "fill",
    scale: "colors",
  },
});
function SVGSpriteV2({ icon, className = "", ...rest }) {
  return (
    <svg className={`icon ${icon} ${className}`} {...rest}>
      <use
        xlinkHref={`${process.env.PUBLIC_URL}/images/icons.svg?#${icon}`}
        {...rest}
      />
    </svg>
  );
}

export const StyledSvgSprite = styled(SVGSpriteV2)`
  ${space}
  ${color}
    ${layout}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
    ${shadow}
    ${fill}
`;
