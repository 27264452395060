import React, { useEffect } from "react";
import * as qs from 'qs';
import "./index.scss";
import Header from "components/Header";
import SvgSprite from "components/atoms/SvgSprite";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { tenantService } from 'services/tenantService'
import { tostor } from "components/helpers";

const VerifyToken = (props) => {
  const [searchParams] = useSearchParams();
  let history = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');
    tenantService.verifyEmailToken({ Token: token })
      .then((res) => {
        localStorage.setItem('user', JSON.stringify({ ...res.data, token }))
        history('/account-setup')

      })
      .catch((error) => {
        tostor.error(error.message);
        history('/')
      })
    // }

  }, [props]);

  return (
    <div>
      <Header />
      <div className="col-lg-12 d-flex justify-content-center align-items-center left-section flex-column verify-email-body">
        <SvgSprite
          icon={"icon-email-icon"}
          height={"56px"}
          width={"56px"}
          className="email-icon"
        />
        <h3 className="verify-heading">Verifying...</h3>
      </div>
    </div>
  );
};

export default VerifyToken;
