import React from "react";
import { H1, H2, H3, H4, H5, H6 } from "./style";

const renderDisplay = (props) => {
  let { children, variant, ...rest } = props;

  const displayProps = { ...rest };

  return (
    <>
      {["display2XL"].includes(variant) && (
        <H1 {...displayProps}>{children}</H1>
      )}
      {["displayXL"].includes(variant) && <H2 {...displayProps}>{children}</H2>}
      {["displayLG"].includes(variant) && <H3 {...displayProps}>{children}</H3>}
      {["displayMD"].includes(variant) && <H4 {...displayProps}>{children}</H4>}
      {["displaySM"].includes(variant) && <H5 {...displayProps}>{children}</H5>}
      {["displayXS"].includes(variant) && <H6 {...displayProps}>{children}</H6>}
    </>
  );
};

export default renderDisplay;
