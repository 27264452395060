import { css } from "styled-components";

import theme from "../../../foundations/theme";

export const customScroll = ({
  verticalWidth = "14px",
  thumbWidth = "4px",
  thumbColor = theme.gray.shade_200,
}) => css`
  &::-webkit-scrollbar {
    width: ${verticalWidth} !important;
    margin-right: ${verticalWidth} !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: ${thumbWidth} solid transparent !important;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${thumbColor}59;
    transition: all 0.2s ease-in-out;
  }
  &::-webkit-scrollbar:horizontal {
    width: ${verticalWidth};
  }

  &::-webkit-scrollbar-track:horizontal {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border: ${thumbWidth} solid transparent !important;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${thumbColor}59;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${thumbColor} !important;
    }

    &::-webkit-scrollbar-track:horizontal {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: ${thumbColor};
    }
  }

  // Only works for firefox v64 and above
  scrollbar-color: ${thumbColor};
  scrollbar-width: thin;

  &:hover {
    scrollbar-color: ${thumbColor};
  }
`;
