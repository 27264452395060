import axios from "axios";
import { tostor } from "./tostor";
/**
 * @returns Unregister callback
 */

const hideShow = (flag) => {
  const ele = document.getElementById("loaderEl");
  if (ele) {
    setTimeout(() => {
      ele.style.display = flag;
    }, 30);
  }
};

const isHandlerLoading = (config = {}) => {
  return config.hasOwnProperty("isLoading") && config.isLoading ? true : false;
};

export function axiosInterceptors() {
  axios.interceptors.request.use(
    (config) => {
      if (isHandlerLoading(config)) {
        hideShow("flex");
      }

      return config;
    },
    (error) => {
      setTimeout(() => {
        hideShow("none");
      }, 500);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      setTimeout(() => {
        hideShow("none");
      }, 500);
      return response;
    },
    (error) => {
      setTimeout(() => {
        hideShow("none");
      }, 500);
      if (error.response && 504 === error.response.status) {
        tostor.error("Request time out");
      }
      // if (error.response && 401 === error.response.status) {
      //     localStorage.clear();
      //     history.push('/');
      // }
      return Promise.reject(error);
    }
  );
}
