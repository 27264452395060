import React from "react";
import { Steps } from "antd";

export default function StepsBar(props) {
  const { steps = {}, ...rest } = props || {};
  const { Step } = Steps;

  return (
    <>
      <Steps current={1} size="small" labelPlacement="vertical">
        {steps.map((item, index) => (
          <Step
            key={index}
            title={item?.title}
            description={item?.description}
            icon={item?.icon}
            status={item?.status}
          />
        ))}
      </Steps>
    </>
  );
}
