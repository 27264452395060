import styled, { css } from "styled-components";
import { space, color, typography, layout, flexbox } from "styled-system";

const sharedHeadingStyle = css`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
    ${typography}
    text-transform: ${({ textTransform }) => textTransform};
  word-wrap: break-word;
`;

export const H1 = styled.h1`
  ${sharedHeadingStyle}
`;

export const H2 = styled.h2`
  ${sharedHeadingStyle}
`;

export const H3 = styled.h3`
  ${sharedHeadingStyle}
`;

export const H4 = styled.h4`
  ${sharedHeadingStyle}
`;

export const H5 = styled.h5`
  ${sharedHeadingStyle}
`;

export const H6 = styled.h6`
  ${sharedHeadingStyle}
`;
