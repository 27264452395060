import { FlexDiv } from "components/atoms/Box/Box";
import { StyledSvgSprite } from "components/atoms/SvgSprite";
import Typography from "foundations/Typography/V2";
import styled from "styled-components";

export const Icon = styled(StyledSvgSprite)`
  height: 56px;
  width: 56px;
`;
export const BackLink = styled(Typography)`
  font-family: inherit;
  font-size: inherit;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.gray.shade_500};
  &:hover {
    color: ${({ theme }) => theme.gray.shade_500};
  }
`;
export const BackIcon = styled(StyledSvgSprite)`
  height: 20px;
  width: 20px;
  stroke: ${({ theme }) => theme.gray.shade_500};
  margin-right: 8px;
`;
export const IconBox = styled(FlexDiv)`
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
`;
export const EmailId = styled(Typography)`
  font-size: inherit;
  color: inherit;
  line-height: inherit;
`;
export const ResetLink = styled(Typography)`
  font-size: inherit;
  color: ${({ theme }) => theme.primary.mediumBlue.shade_700};
  ${({ clickable }) =>
    clickable &&
    `
  cursor: pointer;
  `}
`;
