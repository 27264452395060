import React from "react";
import CustomButton from "components/atoms/CustomButton";
import AzureAuthenticationContext from "./azure-authentication-context";
import microsoftIcon from "assets/images/microsoft-icon.svg";
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

// Log In, Log Out button
const AzureAuthenticationButton = ({
  onAuthenticated,
  btnLabel = "Sign up with Microsoft",
}) => {
  // Azure client context
  const authenticationModule = new AzureAuthenticationContext();

  const logIn = (method) => {
    const typeName = "loginPopup";
    const logInType = isIE ? "loginRedirect" : typeName;

    // Azure Login
    authenticationModule.login(logInType, returnedAccountInfo);
  };

  const returnedAccountInfo = (user) => {
    // set state
    // setAuthenticated(user.name ? true : false);
    onAuthenticated(user);
    // setUser(user);
  };

  return (
    <div id="authentication">
      <div className="sso-login-box" onClick={() => logIn("loginPopup")}>
        <CustomButton
          variant="default"
          btnLabel={btnLabel}
          icon={microsoftIcon}
          className="ant-btn-lg"
        ></CustomButton>
      </div>
    </div>
  );
};

export default AzureAuthenticationButton;
