export const commonService = {
    _set_localStorage(res, key) {
        if (localStorage) {
            localStorage.setItem(key, JSON.stringify(res))
        }
    },
    _get_localStorage(key) {
        let temp;
        if (localStorage) {
            temp = localStorage.getItem(key);
            return temp && JSON.parse(temp)
        }
    },
    _handleLoader(flag, id) {
        const eleId = id || 'loaderEl';
        const style = flag ? 'flex' : 'none'
        const ele = document.getElementById(eleId);
        if (ele) {
            setTimeout(() => {
                ele.style.display = style;
            }, 30)
        }
    }
}


