import React from "react";
import { useLocation } from "react-router-dom";

import renderText from "./Text";
import renderParagraph from "./Paragraph";
import renderDisplay from "./Display";
import renderLabel from "./Label";
import renderSpan from "./Span";
import renderLinks from "./Link";

import theme from "../theme";

const Typography = React.forwardRef((props, ref) => {
  const { search } = useLocation();

  let { color, ...rest } = props;

  const typographyProps = {
    ...rest,
    color: color || theme.gray.shade_900,
    ref: ref,
  };

  return (
    <>
      {renderText(typographyProps)}
      {/* {renderParagraph(typographyProps)} */}
      {renderDisplay(typographyProps)}
      {/* {renderLabel(typographyProps)} */}
      {renderSpan(typographyProps)}
      {renderLinks(typographyProps, search)}
    </>
  );
});

export default Typography;
