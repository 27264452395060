import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "theme-style/index.scss";

// import Signup from "containers/Signup";
import VerifyEmail from "containers/VerifyEmail";
import AccountSetup from "containers/AccountSetup";
import VerifyToken from "containers/VerifyToken";
import Login from "containers/Login";
import ResetPassword from "containers/ResetPassword";
import ConfirmPassword from "containers/ResetPassword/ConfirmPassword";
import TenantLogin from "containers/Login/TenantLogin";

function Routers() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
      <Routes>
        <Route path="/login" element={<Login />} />
      </Routes>
      {/* <Routes>
        <Route path="/signup" element={<Signup />} />
      </Routes> */}
      <Routes>
        <Route path="/tenant/login" element={<TenantLogin />} />
      </Routes>
      <Routes>
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
      <Routes>
        <Route path="/confirm-password" element={<ConfirmPassword />} />
      </Routes>
      <Routes>
        <Route path="/verify" element={<VerifyEmail />} />
      </Routes>
      <Routes>
        <Route path="/signup/verify" element={<VerifyToken />} />
      </Routes>
      <Routes>
        <Route path="/account-setup" element={<AccountSetup />} />
      </Routes>
    </Router>
  );
}

export default Routers;
