import React from "react";
import { Link } from "react-router-dom";
import iconLogo from "assets/images/website-logo.svg";
import "./index.scss";
import { SignUpLink, SignUpText } from "containers/Login/style";

function Header() {
  return (
    <div className="website-header d-flex align-items-center justify-content-between">
      <div>
        <Link to="/">
          <img src={iconLogo} alt="" className="img-fluid" />
        </Link>
      </div>
      <SignUpText variant="textSM" weight="l400" className="text-center">
        Already have an account?{" "}
        <SignUpLink
          variant="routelink1"
          weight="l500"
          to={"/login"}
          className="text-decoration form-footer-subtext"
        >
          Log in
        </SignUpLink>
      </SignUpText>
    </div>
  );
}

export default Header;
