import React, { useEffect, useState } from "react";
import "./index.scss";
import StepsBar from "components/atoms/StepsBar";
import { ReactComponent as IconStepFinish } from "assets/images/step-finish.svg";
import { ReactComponent as IconStepProgress } from "assets/images/step-progress.svg";
import { ReactComponent as IconStepWait } from "assets/images/step-wait.svg";
import Header from "components/Header";
import SvgSprite from "components/atoms/SvgSprite";
import { history } from "components/helpers";
import { tostor } from "components/helpers";
import { tenantService } from "services/tenantService";

const VerifyEmail = () => {
  const [user, setUser] = useState({});
  const initialTimer = 29;
  const [emailTimer, setEmailTimer] = React.useState(initialTimer);

  const emailTimerHandler = (count) => {
    let totalTime = count;
    let timer = setInterval(function () {
      totalTime = totalTime - 1;
      setEmailTimer(totalTime);
      if (totalTime < 0) {
        clearInterval(timer);
      }
    }, 1000);
    return totalTime;
  };

  useEffect(() => {
    //commonService._handleLoader(/* true/false */)
    const userData = localStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    } else {
      history("/");
    }
    emailTimerHandler(initialTimer);
  }, []);

  const handleResendEmail = () => {
    tenantService
      .signUp(user)
      .then((res) => {
        setEmailTimer(initialTimer);
        emailTimerHandler(initialTimer);
      })
      .catch((error) => {
        tostor.error(error.message);
      });
  };
  useEffect(() => {
    if (emailTimer >= initialTimer) {
      emailTimerHandler(initialTimer);
    }
  }, [emailTimer]);
  const steps = [
    {
      title: "Your details",
      description: "Name and email",
      icon: <IconStepFinish />,
      status: "finish",
    },
    {
      title: "Verification",
      description: "Verify your email",
      icon: <IconStepProgress />,
      status: "process",
    },
    {
      title: "Set up your account",
      description: "Company code and password",
      icon: <IconStepWait />,
      status: "wait",
    },
  ];

  return (
    <div>
      <Header />
      <div className="col-lg-12 d-flex justify-content-center align-items-center left-section flex-column verify-email-body">
        <SvgSprite
          icon={"icon-email-icon"}
          height={"56px"}
          width={"56px"}
          className="email-icon"
        />
        <h3 className="verify-heading">Check your email</h3>
        <p className="verify-content">
          We sent a verification link to {user.Email}.
        </p>
        <div className="verify-supporttext">
          {emailTimer >= 0 ? (
            <>
              Resend Email in{" "}
              <span className="verify-support-timer resent_email">{`00:${
                emailTimer > 9 ? emailTimer : `0${emailTimer}`
              }`}</span>
            </>
          ) : (
            <>
              <div className="text-center">
                <span
                  className="resent_email"
                  onClick={() => {
                    handleResendEmail();
                  }}
                >
                  Resend Email
                </span>
              </div>
            </>
          )}
        </div>

        <div className="d-flex steps-outer">
          <StepsBar steps={steps} />
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
