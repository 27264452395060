const theme = {
  breakpoints: ["640px", "960px", "1600px"],
  fonts: {
    LatoRegular: `'Lato-Regular',sans-serif`,
    LatoMedium: `'Lato-Medium',sans-serif`,
    LatoSemibold: `'Lato-SemiBold',sans-serif`,
    LatoBold: `'Lato-Bold',sans-serif`,
    AvenirRegular: `'Avenir-Regular',sans-serif`,
    AvenirMedium: `'Avenir-Medium',sans-serif`,
    AvenirSemibold: `'Avenir-Semibold',sans-serif`,
    AvenirBold: `'Avenir-Bold',sans-serif`,
  },
  loaderBg: "255,255,255",
  primary: {
    darkBlue: {
      shade_25: "#EDF2F8",
      shade_50: "#CDDBEB",
      shade_100: "#ACC3DE",
      shade_200: "#8BABD1",
      shade_300: "#6A93C3",
      shade_400: "#497BB6",
      shade_500: "#3C6595",
      shade_600: "#2E4E74",
      shade_700: "#203650",
      shade_800: "#0a1f36",
      shade_900: "#070B11",
    },
    mediumBlue: {
      shade_25: "#EBF3FB",
      shade_50: "#C4DBF4",
      shade_100: "#9DC3ED",
      shade_200: "#76ABE5",
      shade_300: "#4F93DE",
      shade_400: "#3A86DA",
      shade_500: "#287BD7",
      shade_600: "#2165B0",
      shade_700: "#123862",
      shade_800: "#203650",
      shade_900: "#0B223B",
    },
  },
  secondary: {
    shade_25: "#F9EDF6",
    shade_50: "#EECAE4",
    shade_100: "#E3A7D2",
    shade_200: "#D884C0",
    shade_300: "#CD61AF",
    shade_400: "#C13E9D",
    shade_500: "#B93B96",
    shade_600: "#9E3280",
    shade_700: "#7B2764",
    shade_800: "#581C47",
    shade_900: "#35112B",
  },
  tertiary: {
    green: "#73c050",
    lightBlue: "#7ca4d6",
    lightGray: "#d1d3d4",
    gray: "#6d6e71",
  },
  base: {
    white: "#ffffff",
    black: "#000000",
  },
  gray: {
    shade_25: "#FCFCFD",
    shade_50: "#F9FAFB",
    shade_100: "#F2F4F7",
    shade_200: "#EAECF0",
    shade_300: "#D0D5DD",
    shade_400: "#98A2B3",
    shade_500: "#667085",
    shade_600: "#475467",
    shade_700: "#344054",
    shade_800: "#1D2939",
    shade_900: "#101828",
  },
};

export default theme;
