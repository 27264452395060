import styled from "styled-components";
import { typography } from "styled-system";

import { ALink, StyledRouterLink } from "../../../components/atoms/Box/Box";

export const Link = styled(ALink)`
  ${typography}
  text-transform: ${({ textTransform }) => textTransform};
  text-decoration: none;
  &:hover {
    color: ${({ color }) => color};
    text-decoration: none;
  }
`;
export const RLink = styled(StyledRouterLink)`
  ${typography}
  text-transform: ${({ texttransform }) => texttransform};
  line-height: ${({ lineheight }) => lineheight};
  text-decoration: none;
  &:hover {
    color: ${({ color }) => color};
    text-decoration: none;
  }
`;
