import { Form, Input } from "antd";
import { FlexContainer, FlexDiv, FlexSection } from "components/atoms/Box/Box";
import CustomButton from "components/atoms/CustomButton";
import Image from "components/atoms/Image";
import { StyledSvgSprite } from "components/atoms/SvgSprite";
import Typography from "foundations/Typography/V2";
import styled from "styled-components";

export const LoginWrapper = styled(FlexSection)`
  flex: 1;
  flex-direction: column;
  padding: 96px 0;
`;
export const Container = styled(FlexContainer)``;
export const StyledForm = styled(Form)`
  width: 100%;
`;
export const FormInput = styled(Input)``;
export const SignInBtn = styled(CustomButton)``;
export const BodyContent = styled(FlexDiv)`
  max-width: 360px;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
export const ImageHolder = styled(FlexDiv)`
  max-width: 106px;
  width: 100%;
  margin-bottom: 64px;
`;
export const ImgLogo = styled(Image)``;
export const FormHeading = styled(Typography)`
  color: ${({ theme }) => theme.gray.shade_900};
`;
export const FormSubHeading = styled(Typography)`
  color: ${({ theme }) => theme.gray.shade_500};
  margin-top: 12px;
`;
export const FormHeadingBox = styled(FlexDiv)`
  flex-direction: column;
  text-align: center;
  margin-bottom: 32px;
  width: 100%;
`;
export const SignUpText = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.gray.shade_500};
`;
export const SignUpLink = styled(Typography)`
  font-size: inherit;
`;
export const AccountListBox = styled(FlexDiv)`
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
  max-height: 400px;
  overflow: auto;
`;
export const AccountTitle = styled(Typography)`
  color: ${({ theme }) => theme.gray.shade_700};
  line-height: 20px;
  text-transform: capitalize;
`;
export const AccountSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.gray.shade_500};
  line-height: 20px;
  text-transform: capitalize;
`;
export const AccountList = styled(FlexDiv)`
  cursor: pointer;
  padding: 15px 48px 15px 16px;
  border: 1px solid ${({ theme }) => theme.gray.shade_200};
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  ${({ isActive, theme }) =>
    isActive &&
    `
    border-color: ${theme.primary.mediumBlue.shade_600};
    background-color: ${theme.primary.mediumBlue.shade_25};
    ${AccountSubTitle} {
      color: ${theme.primary.mediumBlue.shade_600};
    }
  `}
  &:hover {
    border-color: ${({ theme }) => theme.primary.mediumBlue.shade_600};
    background-color: ${({ theme }) => theme.primary.mediumBlue.shade_25};
    ${AccountSubTitle} {
      color: ${({ theme }) => theme.primary.mediumBlue.shade_600};
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
export const LHSBox = styled(FlexDiv)`
  flex: 1;
  padding-right: 16px;
  flex-direction: column;
`;
export const ArrowRight = styled(StyledSvgSprite)`
  height: 24px;
  width: 24px;
  fill: ${({ theme }) => theme.gray.shade_700};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
`;
export const Badge = styled(Typography)`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.primary.mediumBlue.shade_50};
  padding: 2px 8px;
  color: ${({ theme }) => theme.primary.mediumBlue.shade_700};
  text-transform: capitalize;
`;
export const FormItem = styled(FlexDiv)`
  flex-direction: column;
  margin-bottom: 32px;
`;
export const CompanyCodeBox = styled(FlexDiv)`
  flex-direction: column;
  width: 100%;
  ${FormItem} {
    margin-bottom: 0px;
  }
`;
export const CompanyHeading = styled(Typography)`
  color: ${({ theme }) => theme.gray.shade_700};
  margin-bottom: 6px;
`;
export const CompanySubHeading = styled(Typography)`
  color: ${({ theme }) => theme.gray.shade_500};
  margin-bottom: 6px;
`;
export const LoginBtnItem = styled(FlexDiv)`
  flex-direction: column;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`;
export const LoginOptionBox = styled(FlexDiv)`
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;
export const ForgotPassword = styled(Typography)`
  position: absolute;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.primary.mediumBlue.shade_700};
`;
export const Button = styled(CustomButton)``;
export const EmptyListBox = styled(FlexDiv)`
  flex-direction: column;
  width: 100%;
  min-height: 104px;
  align-items: center;
  justify-content: center;
`;
export const EmptyText = styled(Typography)`
  color: ${({ theme }) => theme.gray.shade_500};
`;
