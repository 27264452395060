import { FlexDiv } from "components/atoms/Box/Box";
import styled from "styled-components";

export const LoaderWrapper = styled(FlexDiv)`
  display: none;
  align-items: center;
  justify-content: center;
  overflow: auto;
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(${({ theme }) => theme.loaderBg}, 0.53);
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 8px solid ${({ theme }) => theme.primary.mediumBlue.shade_600};
    border-top-color: transparent;
    animation: rotateRing 1s infinite;
  }
  @keyframes rotateRing {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;
