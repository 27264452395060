import styled from "styled-components";
import { space, color, typography, layout, flexbox } from "styled-system";

export const Span = styled.span`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
    ${typography}
    text-transform: ${({ textTransform }) => textTransform};
  word-wrap: break-word;
`;
