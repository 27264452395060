import { FlexDiv } from "components/atoms/Box/Box";
import Typography from "foundations/Typography/V2";
import styled from "styled-components";

export const SeparatorDiv = styled(FlexDiv)`
  position: relative;
  width: 100%;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.gray.shade_200};
  }
`;
export const Text = styled(Typography)`
  position: relative;
  background-color: ${({ theme }) => theme.base.white};
  color: ${({ theme }) => theme.gray.shade_500};
  padding: 0 8px;
`;
