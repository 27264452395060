import { requestGet, requestPost } from "./requests";

/* eslint eqeqeq: 0 */
class TenantService {
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  signUp(data) {
    const url = this.operationUrl("/tenants/sign-up");
    return requestPost(url, data, {
      Authorization: process.env.REACT_APP_USER_API_KEY,
    });
  }

  verifyEmailToken(data) {
    const url = this.operationUrl("/tenants/sign-up/verify");
    return requestPost(url, data, {
      Authorization: process.env.REACT_APP_USER_API_KEY,
    });
  }

  createTenant(data, token) {
    const url = this.operationUrl("/tenants/new");
    return requestPost(url, data, { Authorization: token });
  }

  operationUrl(append) {
    return this.endpoint + append;
  }

  getTenantByEmail(email) {
    const url = this.operationUrl(`/user-tenant/info?email=${email}`);
    return Promise.all([
      requestGet(url, null, {
        Authorization: process.env.REACT_APP_USER_API_KEY,
      }),
      requestGet(
        `https://api.myoloid.co/user-tenant/info?email=${email}`,
        null,
        {
          Authorization:
            "3d330a2195ceb87cb5c212e719aa734fd5c6e3bae0c339b145e804c95e7aa5f8",
        }
      ),
    ])
      .then((res) => {
        let data = [];
        if (res) {
          let productionList = res?.[1]?.data?.Tenants?.map((prodList) => ({
            ...prodList,
            env: "prod",
          }));
          let developmentList = res?.[0]?.data?.Tenants?.map((devList) => ({
            ...devList,
            env: "dev",
          }));

          data = data.concat(productionList, developmentList);
          return data;
        }
      })
      .catch((error) => {
        return error;
      });
  }
  getTenant(tenant) {
    const url = this.operationUrl(`/user-tenant/info?tenant=${tenant}`);
    return Promise.all([
      requestGet(url, null, {
        Authorization: process.env.REACT_APP_USER_API_KEY,
      }),
      requestGet(
        `https://api.myoloid.co/user-tenant/info?tenant=${tenant}`,
        null,
        {
          Authorization:
            "3d330a2195ceb87cb5c212e719aa734fd5c6e3bae0c339b145e804c95e7aa5f8",
        }
      ),
    ])
      .then((res) => {
        let data = [];
        let productionList = res?.[1]?.data?.Tenants?.map((prodList) => ({
          ...prodList,
          env: "prod",
        }));
        let developmentList = res?.[0]?.data?.Tenants?.map((devList) => ({
          ...devList,
          env: "dev",
        }));
        data = data.concat(productionList, developmentList);
        return data;
      })
      .catch((error) => {
        return error;
      });
  }

  login(data, apiUrl) {
    const url = apiUrl + "/login";
    return requestPost(url, data);
  }
  forgotPassword(data, apiUrl) {
    const url = apiUrl + "/user-login/password/forgot";
    return requestPost(url, data);
  }
  resetPassword(data, apiUrl) {
    const url = apiUrl + "/user-login/password/reset";
    return requestPost(url, data);
  }
}

export const tenantService = new TenantService(
  process.env.REACT_APP_API_ENDPOINT
);
