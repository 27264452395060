import React from "react";
import { Button } from "antd";
import "./index.scss";

const noop = () => {};
export default function CustomButton(props) {
  const {
    onClick = noop,
    style = {},
    variant = "primary",
    buttonType = "button",
    className = "btn-custom",
    width,
    icon = "",
    btnLabel = "",
    ...rest
  } = props || {};
  return (
    <Button
      type={variant}
      htmlType={buttonType}
      block
      onClick={onClick}
      className={`btn-custom ${className}`}
      {...rest}
    >
      {icon && <img src={icon} alt="" className="btn-icon" />}
      {btnLabel}
    </Button>
  );
}
