import React from "react";

import { Paragraph } from "../style";
const renderText = (props) => {
  let { children, variant, ...rest } = props;

  const textProps = { ...rest };

  return (
    ["textXL", "textLG", "textMD", "textSM", "textXS"].includes(variant) && (
      <Paragraph {...textProps}>{children}</Paragraph>
    )
  );
};

export default renderText;
