import React from "react";

import TypographyComponent from "../../TypographyComponent";
import theme from "../../theme";

export function Typography(props) {
  const {
    variant,
    fontFamily,
    isExtraBold,
    weight,
    fontSize,
    lineHeight,
    letterSpacing,
    link1Color,
    link2Color,
    mb = "0px",
    ...rest
  } = props;

  const getFontFamily = (weight) => {
    switch (weight) {
      case "a400":
        return theme.fonts.AvenirRegular;
      case "a500":
        return theme.fonts.AvenirMedium;
      case "a900":
        return theme.fonts.AvenirSemibold;
      case "a800":
        return theme.fonts.AvenirBold;
      case "l400":
        return theme.fonts.LatoRegular;
      case "l500":
        return theme.fonts.LatoMedium;
      case "l600":
        return theme.fonts.LatoSemibold;
      case "l700":
        return theme.fonts.LatoBold;
      default:
        return theme.fonts.AvenirRegular;
    }
  };

  const textProps = {
    fontFamily: fontFamily || getFontFamily(weight),
    variant,
    letterSpacing,
    mb: mb,
  };

  const textTypography = {
    textXL: {
      ...textProps,
      fontSize: fontSize || ["20px ", "20px", "20px"],
      lineHeight: lineHeight || ["30px", "30px", "30px"],
    },
    textLG: {
      ...textProps,
      fontSize: fontSize || ["18px", "18px", "18px"],
      lineHeight: lineHeight || ["28px", "28px", "28px"],
    },
    textMD: {
      ...textProps,
      fontSize: fontSize || ["16px", "16px", "16px"],
      lineHeight: lineHeight || ["24px", "24px", "24px"],
    },
    textSM: {
      ...textProps,
      fontSize: fontSize || ["14px", "14px", "14px"],
      lineHeight: lineHeight || ["20px", "20px", "20px"],
    },
    textXS: {
      ...textProps,
      fontSize: fontSize || ["12px", "12px", "12px"],
      lineHeight: lineHeight || ["18px", "18px", "18px"],
    },
  };

  // const paragraphProps = {
  //   fontFamily: fontFamily || getFontFamily(weight),
  //   variant,
  //   mb: mb,
  // };

  // const paragraphTypography = {
  //   paragraph1: {
  //     ...paragraphProps,
  //     fontSize: fontSize || ["16px", "17px", "18px"],
  //     lineHeight: lineHeight || ["24px", "24px", "24px"],
  //     letterSpacing: letterSpacing || ["0.4px", "0.6px", "0.6px"],
  //   },
  //   paragraph2: {
  //     ...paragraphProps,
  //     fontSize: fontSize || ["14px", "15px", "16px"],
  //     lineHeight: lineHeight || ["24px", "24px", "24px"],
  //     letterSpacing: letterSpacing || ["0.4px", "0.6px", "0.6px"],
  //   },
  //   paragraph3: {
  //     ...paragraphProps,
  //     fontSize: fontSize || ["12px", "13px", "14px"],
  //     lineHeight: lineHeight || ["24px", "24px", "24px"],
  //     letterSpacing: letterSpacing || ["0.4px", "0.4px", "0.4px"],
  //   },
  //   paragraph4: {
  //     ...paragraphProps,
  //     fontSize: fontSize || ["10px", "11px", "12px"],
  //     lineHeight: lineHeight || ["16px", "16px", "16px"],
  //     letterSpacing: letterSpacing || ["0.4px", "0.4px", "0.4px"],
  //   },
  // };

  const displayProps = {
    fontFamily: fontFamily || getFontFamily(weight),
    variant,
    letterSpacing,
    mb: mb,
  };

  const displayTypography = {
    display2XL: {
      ...displayProps,
      fontSize: fontSize || ["72px", "72px", "72px"],
      lineHeight: lineHeight || ["90", "90px", "90px"],
      letterSpacing: letterSpacing || ["-0.02rem", "-0.02rem", "-0.02rem"],
    },
    displayXL: {
      ...displayProps,
      fontSize: fontSize || ["60px", "60px", "60px"],
      lineHeight: lineHeight || ["72px", "72px", "72px"],
      letterSpacing: letterSpacing || ["-0.02rem", "-0.02rem", "-0.02rem"],
    },
    displayLG: {
      ...displayProps,
      fontSize: fontSize || ["48px", "48px", "48px"],
      lineHeight: lineHeight || ["60px", "60px", "60px"],
      letterSpacing: letterSpacing || ["-0.02rem", "-0.02rem", "-0.02rem"],
    },
    displayMD: {
      ...displayProps,
      fontSize: fontSize || ["36px", "36px", "36px"],
      lineHeight: lineHeight || ["30px", "30px", "30px"],
      letterSpacing: letterSpacing || ["-0.02rem", "-0.02rem", "-0.02rem"],
    },
    displaySM: {
      ...displayProps,
      fontSize: fontSize || ["30px", "30px", "30px"],
      lineHeight: lineHeight || ["38px", "38px", "38px"],
    },
    displayXS: {
      ...displayProps,
      fontSize: fontSize || ["24px", "24px", "24px"],
      lineHeight: lineHeight || ["32px", "32px", "32px"],
    },
  };

  // const labelProps = {
  //   fontFamily: fontFamily || getFontFamily(weight),
  //   variant,
  //   letterSpacing,
  //   mb: mb,
  // };

  // const labelTypography = {
  //   label1: {
  //     ...labelProps,
  //     fontSize: fontSize || ["16px", "17px", "18px"],
  //     lineHeight: lineHeight || ["24px", "24px", "24px"],
  //   },
  //   label2: {
  //     ...labelProps,
  //     fontSize: fontSize || ["14px", "15px", "16px"],
  //     lineHeight: lineHeight || ["20px", "20px", "24px"],
  //   },
  //   label3: {
  //     ...labelProps,
  //     fontSize: fontSize || ["12px", "13px", "14px"],
  //     lineHeight: lineHeight || ["16px", "16px", "16px"],
  //   },
  //   label4: {
  //     ...labelProps,
  //     fontSize: fontSize || ["10px", "10px", "12px"],
  //     lineHeight: lineHeight || ["18px", "18px", "18px"],
  //   },
  // };

  const spanProps = {
    fontFamily: fontFamily || getFontFamily(weight),
    variant,
    letterSpacing,
    lineHeight,
    mb: mb,
  };

  const spanTypography = {
    span: {
      ...spanProps,
      fontSize: fontSize || "12px",
    },
  };

  const linkProps = {
    fontFamily: fontFamily || getFontFamily(weight),
    variant,
    lineHeight,
    letterSpacing,
    mb: mb,
  };

  const linkTypography = {
    link1: {
      ...linkProps,
      fontSize: fontSize || ["14px", "15px", "16px"],
      lineHeight: lineHeight || ["20px", "20px", "24px"],
      color: link1Color || theme.primary.mediumBlue.shade_700,
    },
    link2: {
      ...linkProps,
      fontSize: fontSize || ["10px", "10px", "14px"],
      color: link2Color || theme.primary.mediumBlue.shade_700,
    },
    routelink1: {
      ...linkProps,
      fontSize: fontSize || ["10px", "12px", "14px"],
      color: link1Color || theme.primary.mediumBlue.shade_700,
    },
    routelink2: {
      ...linkProps,
      fontSize: fontSize || ["10px", "10px", "14px"],
      color: link2Color || theme.primary.mediumBlue.shade_700,
    },
  };

  const typographyV2 = {
    ...textTypography,
    // ...paragraphTypography,
    ...displayTypography,
    // ...labelTypography,
    ...spanTypography,
    ...linkTypography,
  };

  const typographyProps = {
    ...rest,
    ...typographyV2[variant],
  };

  return <TypographyComponent {...typographyProps} />;
}

export default Typography;
