export const isValidEmail = (value) =>
  !!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

export const isValidPassword = (value) => value.length < 8;

export const isValidWorkEmail = (value) => {
  const emailDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "hotmail.co.uk",
    "hotmail.fr",
    "msn.com",
    "yahoo.fr",
    "wanadoo.fr",
    "orange.fr",
    "comcast.net",
    "yahoo.co.uk",
    "yahoo.com.br",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "free.fr",
    "gmx.de",
    "web.de",
    "yandex.ru",
    "ymail.com",
    "libero.it",
    "outlook.com",
    "uol.com.br",
    "bol.com.br",
    "mail.ru",
    "cox.net",
    "hotmail.it",
    "sbcglobal.net",
    "sfr.fr",
    "live.fr",
    "verizon.net",
    "live.co.uk",
    "googlemail.com",
    "yahoo.es",
    "ig.com.br",
    "live.nl",
    "bigpond.com",
    "terra.com.br",
    "yahoo.it",
    "neuf.fr",
    "yahoo.de",
    "alice.it",
    "rocketmail.com",
    "att.net",
    "laposte.net",
    "facebook.com",
    "bellsouth.net",
    "yahoo.in",
    "hotmail.es",
    "charter.net",
    "yahoo.ca",
    "yahoo.com.au",
    "rambler.ru",
    "hotmail.de",
    "tiscali.it",
    "shaw.ca",
    "yahoo.co.jp",
    "sky.com",
    "earthlink.net",
    "optonline.net",
    "freenet.de",
    "t-online.de",
    "aliceadsl.fr",
    "virgilio.it",
    "home.nl",
    "qq.com",
    "telenet.be",
    "me.com",
    "yahoo.com.ar",
    "tiscali.co.uk",
    "yahoo.com.mx",
    "voila.fr",
    "gmx.net",
    "mail.com",
    "planet.nl",
    "tin.it",
    "live.it",
    "ntlworld.com",
    "arcor.de",
    "yahoo.co.id",
    "frontiernet.net",
    "hetnet.nl",
    "live.com.au",
    "yahoo.com.sg",
    "zonnet.nl",
    "club-internet.fr",
    "juno.com",
    "optusnet.com.au",
    "blueyonder.co.uk",
    "bluewin.ch",
    "skynet.be",
    "sympatico.ca",
    "windstream.net",
    "mac.com",
    "centurytel.net",
    "chello.nl",
    "live.ca",
    "aim.com",
    "bigpond.net.au",
  ];
  let result;
  let string = value;
  function getSecondPart(str) {
    return str.split("@")[1];
  }

  if (emailDomains !== null) {
    result = emailDomains.includes(getSecondPart(string));
    return !result;
  }
};

export const passwordValidation = (email = "", password = "") => {
  let result = {
    error: "",
    valid: false,
  };
  let minMaxLength = /^[\s\S]{8,100}$/,
    upper = /[A-Z]/,
    lower = /[a-z]/,
    number = /[0-9]/,
    dictionaryWords = ["password", "helpme", "pa$$word"];
  if (password) {
    if (!minMaxLength.test(password)) {
      return (result = {
        error: "Must be at least 8 characters.",
        valid: true,
      });
    }
    if (!upper.test(password)) {
      return (result = {
        error: "Must contain 1 upper case letter",
        valid: true,
      });
    }
    if (!lower.test(password)) {
      return (result = {
        error: "Must contain 1 lower case letter",
        valid: true,
      });
    }
    if (!number.test(password)) {
      return (result = {
        error: "Must contain a number",
        valid: true,
      });
    }

    if (/(.)\1\1/.test(password)) {
      return (result = {
        error: "Mustn't contain 3 or more same letters",
        valid: true,
      });
    }
    if (dictionaryWords.indexOf(password.toLowerCase()) > -1) {
      return (result = {
        error: "Mustn't contain common words",
        valid: true,
      });
    }
    if (email !== "") {
      let partsOfThreeLetters = email
        ?.match(/.{4}/g)
        ?.concat(
          email?.substr(1).match(/.{4}/g),
          email?.substr(2).match(/.{4}/g)
        );

      RegExp.quote = function (str) {
        return str?.replace(/([.?*+^$[\]\\(){}-])/g, "\\$1");
      };
      if (
        new RegExp(RegExp.quote(partsOfThreeLetters?.join("|")), "i").test(
          password.toLowerCase()
        )
      ) {
        return (result = {
          error: "Mustn't contain email words",
          valid: true,
        });
      }
    }

    // Check for sequential numerical characters
    for (let i in password) {
      if (
        +password[+i + 1] === +password[i] + 1 &&
        +password[+i + 2] === +password[i] + 2
      ) {
        return (result = {
          error: "Mustn't contain sequential numbers",
          valid: true,
        });
      }
    }
    // Check for sequential alphabetical characters
    for (let pass in password) {
      if (
        String.fromCharCode(password.charCodeAt(pass) + 1) ===
          password[+pass + 1] &&
        String.fromCharCode(password.charCodeAt(pass) + 2) ===
          password[+pass + 2]
      ) {
        return (result = {
          error: "Mustn't contain sequential numbers",
          valid: true,
        });
      }
    }
  }
  return result;
};
