import React from "react";

import { Link, RLink } from "./style";

const renderLinks = (props, search) => {
  let { children, variant, href, lineHeight, textTransform, to, ...rest } =
    props;

  const linkProps = {
    lineheight: lineHeight,
    texttransform: textTransform,
    ...rest,
  };

  return (
    <>
      {variant === "link1" && (
        <Link href={href} {...linkProps}>
          {children}
        </Link>
      )}
      {variant === "link2" && (
        <Link href={href} {...linkProps}>
          {children}
        </Link>
      )}
      {variant === "routelink1" && (
        <RLink to={{ pathname: to, search }} {...linkProps}>
          {children}
        </RLink>
      )}
      {variant === "routelink2" && (
        <RLink to={{ pathname: to, search }} {...linkProps}>
          {children}
        </RLink>
      )}
    </>
  );
};

export default renderLinks;
