import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  system,
} from "styled-system";
import { customScroll } from "../../shared/mixin";

export const Box = styled.div`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  ${customScroll({})};
`;

export const FlexDiv = styled.div`
  display: flex;
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  ${customScroll({})};
`;
export const Container = styled.div`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  max-width: 1312px;
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  ${customScroll({})};
`;
export const FlexContainer = styled.div`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  display: flex;
  max-width: 1312px;
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  ${customScroll({})};
`;
export const Section = styled.section`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  ${customScroll({})};
`;
export const FlexSection = styled.section`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  display: flex;
  ${customScroll({})};
`;
export const Form = styled.form`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;
export const StyledRouterLink = styled(Link)`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;
export const StyledRouterNavLink = styled(NavLink)`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;
export const Table = styled.table`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;
export const TR = styled.tr`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;
export const TH = styled.th`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;
export const TD = styled.td`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;
export const Label = styled.label`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
  margin-bottom:0;
`;
export const ALink = styled.a`
  ${flexbox}
  ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${system({
    boxSizing: true,
    cursor: true,
  })}
`;
export default Box;
