import React from "react";
import { SeparatorDiv, Text } from "./style";

function Separator(props) {
  const {
    text = "OR",
    variant = "textSM",
    weight = "l400",
    mb = "24px",
  } = props || {};
  return (
    <SeparatorDiv mb={mb}>
      <Text variant={variant} weight={weight}>
        {text}
      </Text>
    </SeparatorDiv>
  );
}

export default Separator;
